html[data-theme='merchant-rails'] {
  /* Spacing  */
  --spacing-fit-content: fit-content;
  --spacing-login-logo-desktop: calc(100vh - 230px);
  --spacing-content-fit-mobile: calc(100vh - 88px);
  --spacing-content-fit-desktop: calc(100vh - 120px);
  --spacing-active-step: calc(100% + 20px);
  --spacing-26_43: 26.43%;
  --spacing-27: 27.08%;
  --spacing-50: 50%;
  --spacing-75: 75%;
  --spacing-6: 6.5px;
  --spacing-3px: 3px;
  --spacing-5px: 5px;
  --spacing-13px: 13px;
  --spacing-14px: 14px;
  --spacing-18px: 18px;
  --spacing-22px: 22px;
  --spacing-23px: 23px;
  --spacing-27px: 27px;
  --spacing-35px: 35px;
  --spacing-38px: 38px;
  --spacing-43px: 43px;
  --spacing-45px: 45px;
  --spacing-49px: 49px;
  --spacing-50px: 50px;
  --spacing-52px: 52px;
  --spacing-57px: 57px;
  --spacing-60px: 60px;
  --spacing-61px: 61px;
  --spacing-64px: 64px;
  --spacing-73px: 73px;
  --spacing-80px: 80px;
  --spacing-83px: 83px;
  --spacing-88px: 88px;
  --spacing-103px: 103px;
  --spacing-155px: 155px;
  --spacing-165px: 165px;
  --spacing-168px: 168px;
  --spacing-184px: 184px;
  --spacing-232px: 232px;
  --spacing-274px: 274px;
  --spacing-240px: 240px;
  --spacing-330px: 330px;
  --spacing-350px: 350px;
  --spacing-400px: 400px;
  --spacing-450px: 450px;
  --spacing-496px: 496px;
  --spacing-508px: 508px;
  --spacing-570px: 570px;
  --spacing-596px: 596px;
  --spacing-624px: 624px;
  --spacing-680px: 680px;
  --spacing-90screen: 90vh;

  /* Line Heights */
  --line-height-24px: 24px;
  --line-height-26px: 26px;
  --line-height-32px: 32px;
  --line-height-48px: 48px;
  --line-height-52px: 52px;
  --line-height-56px: 56px;
  --line-height-67_2px: 67.2px;

  /* Colors */
  --color-b-grey: 216 216 216;
  --color-splitter-grey: 229 231 235;
  --color-green-button-disabled: 202 202 202;
  --color-indingo-bloom: 67 87 173;
  --color-light-blue-bloom: 52 180 235;
  --color-light-grey: 250 250 250;
  --color-text-color-bloom: 33 33 33;
  --color-faded-strawberry-bloom: 255 115 115;
  --color-body-grey: 250 248 248;
  --color-text-color-grey-bloom: 165 164 164;
  --color-header-table-gray: 150 150 150;
  --color-gradient-gray: 247 243 243;
  --color-input-border-color-bloom: 162 162 162;
  --color-grey-button: 162 162 162;
  --color-grey-rectangle: 235 235 235;
  --color-grey-group: 93 94 110;
  --color-green-bloom: 2 210 135;
  --color-pink-bloom: 252 77 203;
  --color-orange-bloom: 255 190 115;
  --color-purple-bloom: 77 64 131;
  --color-grey-blue-bloom: 240 242 249;
  --color-b-dark: 23 2 83;
  --color-b-purple: 77 64 131;
  --color-b-pink: 252 77 203;
  --color-b-yellow: 250 240 27;
  --color-b-placeholder: 169 167 178;
  --color-b-lite: 246 245 250;
  --color-divide-grey: 197 195 203;
  --color-button-back: 147 146 150;
  --color-button-next: 253 0 131;
  --color-error-message: 253 5 133;
  --color-dark-grey: 55 65 81;
  --color-yellow-color: 250 240 27;
  --color-pink-color: 253 0 131;
  --color-dark-blue: 23 25 48;
  --color-divider-dark-blue: 46 48 76;
  --color-placeholder-grey: 147 146 150;
  --color-input-focus: 146 170 219;
  --color-light-grey-100: 247 247 247;
  --color-light-grey-200: 237 237 237;
  --color-light-grey-300: 198 198 198;
  --color-light-grey-400: 229 229 229;
  --color-teal-color: 80 227 194;
  --color-facebook: 0 132 229;
  --color-google: 66 133 244;

  /* Box Shadows */
  --box-shadow-card-header-white: inset 0px -2px 0px #faf8f8;
  --box-shadow-row-table: inset 0px 2px 0px #faf8f8;
  --box-shadow-card-header-gray: inset 0px -2px 0px #eee8e9;
  --box-shadow-sidebar-active: inset 2px 0px 0px #02d287;
  --box-shadow-funds-utilized: inset 0px -4px 0px #faf8f8;
  --box-shadow-header-dropdown: 0px 4px 12px rgba(0, 0, 0, 0.24);
  --box-shadow-login-form: 0px 32px 64px rgba(169, 167, 178, 0.12);
  --box-shadow-sale-box: inset 0px 4px 0px #4357ad;
  --box-shadow-header-dark-grey: inset 0px -1px 0px #e5e7eb;
  --box-shadow-active-step: 0px 0px 10px 4px rgba(0, 0, 0, 0.15);

  /* Min Widths */
  --min-width-login-fit: calc(100vh - 144px);
  --min-width-6: 1.5rem;
  --min-width-12: 3rem;
  --min-width-fit-content: fit-content;

  /* Max Widths */
  --max-width-fit-content: fit-content;
  --max-width-400px: 400px;
  --max-width-464px: 464px;
  --max-width-496px: 496px;
  --max-width-624px: 624px;
  --max-width-140: 140%;
  --max-width-75: 75%;

  /* Min Heights */
  --min-height-content-fit-mobile: calc(100vh - 88px);
  --min-height-content-fit-desktop: calc(100vh - 130px);

  /* Insets */
  --inset-14px: 14px;
  --inset-24px: 24px;

  /* Border Radius */
  --border-radius-btn: 1.75rem;
  --border-radius-group: 6px;
  --border-radius-best: 33px;

  /* Font Sizes */
  --font-size-13px: 13px;
  --font-size-16px: 16px;
  --font-size-20px: 20px;
  --font-size-32px: 32px;
  --font-size-33px: 33px;
  --font-size-40px: 40px;
  --font-size-50px: 50px;
  --font-size-8: 2rem;

  /* Box Shadows */
  --box-shadow-button: 0 15px 15px -10px rgb(0 0 0 / 50%);

  /* Background Sizes */
  --background-size-200: 200%;
}
