@import 'react-toastify/dist/ReactToastify.css';

.Toastify__toast-theme--light {
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: inset 0px -1px 0px #e5e7eb;
  color: #374151;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.Toastify__close-button--light {
  color: #374151;
  opacity: 1;
  transition-duration: 0.15s;
}

.Toastify__close-button--light:hover {
  opacity: 0.8;
}

.Toastify__toast--success .Toastify__toast-icon svg {
  fill: #44d7b6;
}

.Toastify__toast--error .Toastify__toast-icon svg {
  fill: #fd0083;
}
