.react-tel-input .flag-dropdown {
  position: relative;
  padding: 16px !important;
  background: transparent !important;
  border: 0 !important;
  border-radius: 8px !important;
  box-sizing: border-box !important;
}

.react-tel-input .flag-dropdown:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 16px;
  background-color: #374151;
  transform: translateY(-50%);
}

.react-tel-input .form-control {
  width: 100% !important;
  height: 100% !important;
  border: 0.5px solid #a9a7b2 !important;
  padding-left: 60px !important;
  background-color: #ffffff !important;
  font-size: 16px !important;
  transition: border-color 0.3s !important;
  outline: none;
}

.react-tel-input .form-control:focus {
  background-color: #fff !important;
  border: 2px solid #374151 !important;
}

.react-tel-input.border-error-message .form-control {
  border: 2px solid #fd0585 !important;
}

.react-tel-input.border-error-message .form-control:focus {
  border: 2px solid #92aadb !important;
}

.react-tel-input .selected-flag {
  padding: 0 !important;
  width: 16px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .country-list {
  top: 0 !important;
  left: 0 !important;
  margin: 62px 0 0 2px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 1px solid #e5e7eb !important;
}

.react-tel-input .selected-flag .arrow {
  display: none !important;
}
