@import 'stylesheets/fonts.css';

@import 'stylesheets/phoneinput.css';

@import 'stylesheets/toast.css';

@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

@import 'react-datepicker/dist/react-datepicker.css';

@import 'react-phone-input-2/lib/style.css';

@import 'stylesheets/tooltip.css';

@import 'stylesheets/global.css';

@import 'rc-slider/assets/index.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'stylesheets/themes/merchant-rails.css';
@import 'stylesheets/themes/chassi.css';
