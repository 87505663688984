input[type='radio']:checked + label span {
  background-color: #fd0083;
  box-shadow: 0px 0px 0px 2px white inset;
}

input::-ms-reveal,
input::-ms-clear {
  margin-right: 25px;
}

/* Needed to hide the Zoho Chat button */
#zsiqbtn {
  display: none !important;
}

body {
  background: #f7f7f7;
}

/* Styles for Birthday date picker. Adds styles to inner wrapper from react-datepicker*/
.datepicker_wrapper {
  z-index: 5;
}

.datepicker_wrapper > div {
  position: absolute;
  margin-top: 1rem;
  left: 50%;
  transform: translateX(-50%);
}
